import AvatarService from '@/services/avatar.service.js';

const service = new AvatarService();

export default {
    name: 'AvatarUploader',
    components: {},
    props: {
        uploadType: String,
        defaultType: String,
        onChange: Function
    },
    data() {
        return {
            types: [],
            type: this.defaultType,
            loadedImages: []
        }
    },
    computed: {},
    watch: {
        type() {
            if (this.onChange) this.onChange(this.loadedImages, this.type);
        }
    },
    async mounted() {
        const res = await service.categories();

        if ( res && !res.error ){
            this.types = res.data;
        }

    },
    methods: {
        handleChange(e) {
            for (let image of e.target.files) {
                let img = {
                    image: image,
                    imageId: null,
                    data: null
                };
                const reader = new FileReader();

                const $data = new FormData();
                $data.append('title', 'Upload Photo');
                $data.append('file', image);

                reader.onloadend = async () => {
                    this.loadedImages.push(img);
                    img.data = reader.result;

                    const res = await service.upload($data);
                    if ( res && !res.error ) {
                        img.imageId = res.data._id;
                        img.imageName = res.data.Name;


                        const index = this.loadedImages.find(p => p.imageName === img.imageName);

                        if ( !index ){

                            if ( this.onChange ) this.onChange(this.loadedImages, this.type);
                        }
                    }

                };

                reader.readAsDataURL(image);
            }
        },

        deleteImage(image){
            const index = this.loadedImages.findIndex(i => i.imageId === image.imageId);

            if ( index > -1  ){
                this.loadedImages.splice(index, 1);
                if ( this.onChange ) this.onChange(this.loadedImages, this.type);
            }

        }

    }
}
